import FormInvitation from "./components/pages/FormInvitation";

function App() {
  return (
    <div className="App container mx-auto px-4">
      <div className="flex justify-center h-screen items-center">
        <FormInvitation />
      </div>
    </div>
  );
}

export default App;
