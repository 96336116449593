import { useEffect, useState } from "react";
import Card from "../general/Card";
import Button from "../general/Button";

const text = `بِسْــــــــــــــمِ اللهِ الرَّحْمَنِ الرَّحِيْـــــم

💐 UNDANGAN PERNIKAHAN 💐

Kepada Yth:
{{name}}

Segala puji bagi Allah SWT yang telah menciptakan makhluk-Nya berpasang-pasangan. Dengan ini kami bermaksud mengundang Bapak/Ibu/Saudara/i untuk menghadiri acara pernikahan kami :

Anita Nur Hidayah
Putri Pertama dari Bapak Wawan Karnoto Sp.d dan Ibu Warjini Sp.d 

dengan

Nanda Yusuf Nur Pratama
Putra Pertama dari Bapak Sartono dan Ibu Muryanti

Insyaa Allah yang akan diselenggarakan pada:
15 April 2024

🏢 Tempat Acara
Gedung Pertemuan PGRI, Singkil, Boyolali FJH5+GP3, Jl. Pringgondani, Dusun 1, Karanggeneng, Kec. Boyolali, Kabupaten Boyolali, Jawa Tengah 57312
Klik link berikut untuk Undangan Resmi
[ wedding.nandaynp.my.id  ]

Merupakan suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan memberikan do'a terbaiknya.


Jazaakumullah Khairan Katsiran

💌
Wedding E-Invitation ini merupakan undangan resmi dari kami, karena jarak & waktu kami mohon maaf apabila mengirim undangan ini melalui media online. Semoga tidak mengurangi rasa hormat dan makna serta isinya.

Kami yang berbahagia,
Anita & Nanda`;

const FormInvitation = () => {
  const [name, setName] = useState('');
  const [previewText, setPreviewText] = useState(text);

  useEffect(() => {
    document.title = 'E-Invitation Generator'
  }, [])
  

  const handleInputChange = (event: any) => setName(event.target.value);

  useEffect(() => {
    const regex = /\{\{name\}\}/g;
    const resultString = text.replace(regex, name);
    setPreviewText(resultString);

  }, [name])


  const sendToWhatsapp = () => {
    const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(previewText)}`;

    if (window) window.open(whatsappUrl, '_blank');
  };

  const shareContent = () => {
    try {
      navigator.share({
        text: previewText
      });
    } catch (error) {
      alert("Can't share this content.")
    }
  };

  return (
    <Card>
      <div className="flex flex-col gap-4 p-6">
        <div className="relative h-80 w-full min-w-[200px] mb-10">
          <span>Formated Preview</span>
          <div className="p-4 shadow rounded max-h-80 overflow-y-scroll break-words whitespace-pre-wrap">
            <div>
              {previewText}
            </div>
          </div>
        </div>
        <div className="relative h-11 w-full min-w-[200px]">
          <input
            className="w-full h-full px-3 py-3 font-sans text-sm font-normal transition-all bg-transparent border rounded-md peer border-blue-gray-200 border-t-transparent text-blue-gray-700 outline outline-0 placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
            placeholder=" "
            value={name}
            onChange={handleInputChange} />
          <label
            className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Name
          </label>
        </div>
      </div>
      <div className="p-6 pt-0">
        <Button label="Share" onClick={shareContent} />
      </div>
      <div className="p-6 pt-0">
        <Button label="Send to Whatsapp" onClick={sendToWhatsapp} />
      </div>
    </Card>
  )
}

export default FormInvitation