import { FC, ReactNode } from "react"

interface CardProps {
  children: ReactNode;
  className?: string;
}

const Card: FC<CardProps> = ({ className, children }) => {

  return (
    <div className={`relative flex flex-col text-gray-700 bg-white shadow-md rounded-xl bg-clip-border ${className}`}>
      {children}
    </div>
  )
}

export default Card